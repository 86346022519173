/** Shelf Life: Plaque **/

import React from "react";
import { preloadImages, showText, hideText, positionText, resetAnimationDelay } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import gsap from "gsap";

let globalState, data, preloadData;

class ShelfLifePage4 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[4];
        preloadData = globalState.assets[5];

        //refs
        this.fullscreenBg = null;
    }

    state = {
        textVisible: false,
    }




    componentDidMount = () => {


        //position text
        positionText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


        //show text
        gsap.to(this.refs.text1Ref, 0, { autoAlpha: 1.0 });

        //add base track
        //globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);


        //globalState.baseAudioRef.updateTrack(2, false);

        //Preload assets from next page
        preloadImages([
            globalState.cdnUrl + preloadData.caption,
            globalState.cdnUrl + preloadData.bgVideoPoster,
            globalState.cdnUrl + preloadData.demo1Poster,
            globalState.cdnUrl + preloadData.demo2Poster,
            globalState.cdnUrl + preloadData.demo3Poster,
            globalState.cdnUrl + preloadData.demo4Poster,
            globalState.cdnUrl + preloadData.demo5Poster,
            globalState.cdnUrl + preloadData.demo6Poster,
            globalState.cdnUrl + preloadData.demo7Poster,
            globalState.cdnUrl + preloadData.demo8Poster,
            globalState.cdnUrl + preloadData.demo9Poster,
            globalState.cdnUrl + preloadData.demo10Poster,
            globalState.cdnUrl + preloadData.demo11Poster,
            globalState.cdnUrl + preloadData.demo12Poster,
        ], this.preloadCallback);

    }

    preloadCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);
    }


    toggleTextDisplay = (event) => {

        //toggle text visibility
        this.setState({ textVisible: !this.state.textVisible });

        //reset the animation delay
        resetAnimationDelay();

        if (this.state.textVisible) {
            //show text
            showText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


        } else {
            //hide text
            hideText(this.refs.text1Ref, "right");

        }
    }

    isTransitioning = () => {

        //pause animations
    }


    render() {

        return (

            <>

                <link rel="prefetch" href="/shelf-life/5" />
                {preloadData && (
                <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo} />
                )}

                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/shelf-life/5" prevLocation="/shelf-life/3"
                    isTransitioning={this.isTransitioning} />
                {data && (
                <div className="fullpage-wrapper" style={{ backgroundImage: `url(${globalState.cdnUrl + data.background})`, backgroundSize: "cover", backgroundPosition: "top center" }}>
                    <SEO title="Pine Point - Shelf Life" />

                    <div className="text-wrapper">
                        <div className="text text--right-top" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "0", marginTop: "100px" }} onClick={this.toggleTextDisplay} alt="What remains of Pine Point is an unfinished sentence." />
                        </div>
                    </div>

                    <img src={globalState.cdnUrl + data.credit} className="credit" alt="" style={{position: 'absolute', right: 0, bottom: '10px'}} />

                </div>
                )}
            </>
        );
    }


};

export default ShelfLifePage4
